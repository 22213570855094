import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Column, ItemsList } from "./utils"
import { Lead } from "./typography"
import one from "../images/01.svg"
import two from "../images/02.svg"
import four from "../images/400.svg"
import arrowH from "../images/arrow-h.svg"

const Phases = () => (
  <Wrapper>
    <CustomContainer>
      <CustomRow>
        <Column>
          <Box>
            <img src={one} />
            <Lead color={DesignTokens.colors.primary[500]} uppercase>
              Preparazione degli attrezzi per la promozione digitale
            </Lead>
            <ItemsList>
              <li>
                <strong>Scelta e segmentazione del target</strong>
                <br /> (paesi, mercati, dimensione)
              </li>
              <li>
                <strong>Redazione del messaging di primo contatto</strong>
                <br />
                multilingua ottimizzati per canale e per target
              </li>
              <li>
                <strong>Preparazione delle company presentation</strong>
                <br />
                perfezionata multilingua (ppt, prezi, spark, google, ...)
              </li>
            </ItemsList>
          </Box>
        </Column>
        <Column>
          <Box>
            <img src={two} />
            <Lead color={DesignTokens.colors.primary[500]} uppercase>
              Sviluppo dell'attività commerciale
            </Lead>
            <ItemsList>
              <li>
                <strong>Selezione e profilazione dei prospect</strong>
                <br /> con metodo Similarity, Boundaries, MULTI CRM
              </li>
              <li>
                <strong>Contatto diretto multicanale</strong>
              </li>
              <li>
                <strong>
                  Nurturing digitale con utilizzo puntuale di DEM e social
                </strong>
              </li>
              <li>
                <strong>Gestione delle richieste di offerta</strong>
              </li>
            </ItemsList>
          </Box>
        </Column>
      </CustomRow>
      <CustomRow>
        <Results>
          <Lead color={DesignTokens.colors.white} uppercase>
            Risultati
          </Lead>
          <img src={four} />
          <Lead color={DesignTokens.colors.white}>
            <span>nuovi potenziali clienti</span> interessanti e interessati a{" "}
            <span>sviluppare business</span> con la tua impresa
          </Lead>
        </Results>
      </CustomRow>
    </CustomContainer>
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 5rem 0;
`

const CustomContainer = styled(Container)`
  & > * {
    margin-bottom: ${DesignTokens.spacing[6]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomRow = styled(Row)`
  margin-left: 10%;
  margin-right: 10%;
  background-image: url(${arrowH});
  background-repeat: no-repeat;
  background-position: center center;
  @media screen and (max-width: 768px) {
    background-image: none;
    & > * {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Results = styled.div`
  text-align: center;
  padding: 4rem 15%;
  background: ${DesignTokens.colors.primary[500]};
  & > * {
    margin-bottom: ${DesignTokens.spacing[8]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Box = styled.div`
  border: 1px solid ${DesignTokens.colors.primary[500]};
  text-align: center;
  padding: 4rem 2rem;
  height: 100%;
  & > ul {
    & > li {
      line-height: 1.4;
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  & > * {
    margin-bottom: ${DesignTokens.spacing[7]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default Phases
