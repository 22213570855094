import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Top from "../components/top"
import Phases from "../components/phases"
import CallToAction from "../components/callToAction"

const IndexPage = () => (
  <Layout>
    <SEO title="Business Expander" />
    <Top />
    <Phases />
    <CallToAction />
  </Layout>
)

export default IndexPage
