import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Column } from "./utils"
import { Display, Heading, Body } from "./typography"
import Header from "./header"
import illustration from "../images/illustration-top.png"
import { LinkButtonSecondary } from "./buttons"

const Top = () => (
  <Wrapper>
    <Header />
    <CustomContainer>
      <Row>
        <Content>
          <Display color={DesignTokens.colors.white}>Business Expander</Display>
          <Heading color={DesignTokens.colors.white}>
            Un piano di azioni in <span>due fasi</span> per dare una{" "}
            <span>spinta alle vendite</span> della tua azienda in fase di{" "}
            <span>ripartenza</span>
          </Heading>
          <LinkButtonSecondary
            href="https://calendly.com/multiconsult/consulenza"
            target="blank"
            rel="nofollow noopener"
          >
            Richiedi una consulenza gratuita <span>⟶</span>
          </LinkButtonSecondary>
        </Content>
        <Image>
          <img src={illustration} />
        </Image>
      </Row>
    </CustomContainer>
  </Wrapper>
)

const Image = styled(Column)`
  text-align: center;
`

const Content = styled(Column)`
  padding: ${DesignTokens.spacing[8]} 0;
  & > * {
    margin-bottom: ${DesignTokens.spacing[7]};
    &:last-child {
      margin-bottom: 0;
    }
  }
  hr {
    width: 10%;
    height: px;
    background: white;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const CustomContainer = styled(Container)`
  padding: 3rem 0;
`

const Wrapper = styled.div`
  background: ${DesignTokens.colors.primary[500]};
`

export default Top
