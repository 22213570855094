import styled from "styled-components"
import DesignTokens from "./designTokens"

const Display = styled.h1`
  font-size: ${DesignTokens.fontSizes[10]};
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  color: ${props => props.color || "inherit"};
  @media screen and (max-width: 375px) {
    font-size: ${DesignTokens.fontSizes[8]};
  }
`
const DisplaySmall = styled.h2`
  font-size: ${DesignTokens.fontSizes[7]};
  font-weight: 800;
  text-transform: ${props => (props.uppercase ? "uppercase" : "none")};
  color: ${props => props.color || "inherit"};
`
const Heading = styled.h3`
  font-size: ${DesignTokens.fontSizes[7]};
  font-weight: normal;
  color: ${props => props.color || "inherit"};
  line-height: 1.3;
  span {
    background: ${DesignTokens.colors.black};
    color: ${DesignTokens.colors.white};
  }
  @media screen and (max-width: 375px) {
    font-size: ${DesignTokens.fontSizes[7]};
  }
`
const HeadingSmall = styled.h4`
  font-size: ${DesignTokens.fontSizes[6]};
  font-weight: 500;
  line-height: 1.1;
  color: ${props => props.color || "inherit"};
`
const Lead = styled.h5`
  color: ${props => props.color};
  font-size: ${DesignTokens.fontSizes[6]};
  font-weight: 900;
  line-height: 1.2;
  text-transform: ${props => (props.uppercase ? "uppercase" : "none")};
  span {
    color: ${DesignTokens.colors.black};
  }
`
const Body = styled.p`
  font-size: ${DesignTokens.fontSizes[4]};
  font-weight: ${props => props.weight || "normal"};
  color: ${props => props.color || "inherit"};
  line-height: 1.4;
  span {
    color: ${DesignTokens.colors.black};
  }
`
const BodySmall = styled.p`
  font-size: ${DesignTokens.fontSizes[3]};
  font-weight: 500;
  color: ${props => props.color || "inherit"};
`
const Caption = styled.p`
  font-size: ${DesignTokens.fontSizes[4]};
  font-weight: 500;
  color: ${props => props.color || "inherit"};
`
const CaptionSmall = styled.p``

export {
  Display,
  DisplaySmall,
  Heading,
  HeadingSmall,
  Lead,
  Body,
  BodySmall,
  Caption,
  CaptionSmall,
}
