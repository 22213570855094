import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, ColumnThird, ColumnSeventh } from "./utils"
import gear from "../images/illustration-cta.png"
import { DisplaySmall } from "./typography"
import { LinkButtonSecondary } from "./buttons"

const CallToAction = () => (
  <Wrapper>
    <Container>
      <CustomRow>
        <Contact>
          <DisplaySmall color={DesignTokens.colors.white} uppercase>
            Rimetti in moto le macchine per vendere, contattaci!
          </DisplaySmall>
          <LinkButtonSecondary
            href="https://calendly.com/multiconsult/consulenza"
            target="blank"
            rel="nofollow noopener"
          >
            Richiedi una consulenza gratuita <span>⟶</span>
          </LinkButtonSecondary>
        </Contact>
        <Image>
          <img src={gear} />
        </Image>
      </CustomRow>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div``

const Image = styled(ColumnThird)`
  text-align: center;
`

const Contact = styled(ColumnSeventh)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  & > * {
    margin-bottom: ${DesignTokens.spacing[7]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomRow = styled(Row)`
  background: ${DesignTokens.colors.primary[500]};
  padding: ${DesignTokens.spacing[7]} ${DesignTokens.spacing[10]};
`
export default CallToAction
